import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
//DATA
//STYLES
import { H1, H2, H4, LabelBW, Text } from "../utils/typo"
import { ContainerMob, Section, Logo, CardWrapper } from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"
import Layout from "../layout/layout"

const SeoImage = styled(ContainerMob)`
  //height: 0;
  //overflow: hidden;
`

const Praca = () => {
  return (
    <div>
      <Seo
        title="Praca"
        slug="/praca"
        description="Praca w drukarni na wydziale Architektury Politechniki Śląskiej w Gliwicach - obsługa klienta"
      />
      <Layout>
        <Logo />
        <Section pt="6rem" pb="3rem">
          <ContainerMob>
            <LabelBW mb="2rem">Praca</LabelBW>
            <H1>Praca w drukarni</H1>
            <Text weight="700">
              — Jesteśmy prężnie rozwijającą się firmą świadczącą kompleksowe
              usługi poligraficzne i projektowe.
              <br />
              Poszukujemy osoby, która uzupełni nasz zespół i pomoże w
              usprawnieniu obsługi klientów.
            </Text>
            <Text mb="2rem" mt="2rem">
              Wysoki standard obsługi klientów jest dla nas bardzo istotny,
              dlatego poszukujemy osoby kompetentnej, <br />
              która z uśmiechem na twarzy zaopiekuje się naszymi klientami oraz
              ich zleceniami.
            </Text>

            <CardWrapper xl="2" l="2" m="2" colGap="2rem">
              <div>
                <H4>Zakres Obowiązków</H4>

                <ul>
                  <li>odbieranie maili, odpisywanie, pobieranie załączników</li>
                  <li>odbieranie telefonów</li>
                  <li>drukowanie pobranych załączników</li>
                  <li>wydawanie gotowych wydruków</li>
                  <li>drukowanie plików przyniesionych na nośnikach</li>
                  <li>
                    bindowanie, laminowanie, składanie, przycinanie wydruków
                  </li>
                  <li>sprzedaż towarów i usług</li>
                </ul>
              </div>
              <div>
                <H4>Wymagania</H4>
                <ul>
                  <li>
                    znajomość obsługi komputera - pisania maili, obsługi
                    programu Word
                  </li>
                  <li>wysoka kultura osobista w kontaktach z klientami</li>
                  <li>
                    otwartość i chęć do nauki nowych programów oraz obsługi
                    sprzętów (drukarki)
                  </li>
                  <li>sumienność</li>
                  <li>utrzymywanie porządku na stanowisku pracy</li>
                </ul>
              </div>
            </CardWrapper>

            <H4 mt="3rem">My ze swojej strony oferujemy:</H4>
            <ul>
              <li>Możliwość zatrudnienia na umowę o pracę / umowę zlecenie</li>
              <li>Stałość zatrudnienia</li>
              <li>
                Praca w samym sercu kampusu Politechniki Śląskiej w Gliwicach -
                Wydział Architektury
              </li>
              <li>
                Wyrozumiałość: nie musisz wiedzieć wszystkiego, przecież tego
                można się nauczyć!
              </li>
              <li>Przyjazna atmosfera</li>
              <li>Możliwość ciągłego rozwoju</li>
            </ul>

            <H4 mt="3rem">
              Aplikacje prosimy przesyłać na adres:
              <br />
              iks.druk@gmail.com
            </H4>

            <Text mb="2rem" mt="2rem">
              max. rozmiar załącznika mailowego: 25MB <br />
              <strong>CV powinno zawierać klauzulę informacyjną:</strong> <br />
              "Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb
              niezbędnych do realizacji procesu rekrutacji zgodnie z
              Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z
              dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
              związku z przetwarzaniem danych osobowych i w sprawie swobodnego
              przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO)."
            </Text>
          </ContainerMob>

          <H2 as="p" textAlign="center">
            Nie możemy się doczekać aby Cię poznać!
          </H2>
        </Section>
        {/* <SeoImage>
          <StaticImage
            placeholder="blurred"
            alt="Praca w drukarni IKS druk"
            src="../assets/images/praca.jpg"
            layout="fullWidth"
            objectFit="cover"
            formats={["png"]}
          />
        </SeoImage> */}
      </Layout>
    </div>
  )
}
export default Praca
